"use client";

import {useCallback, useEffect, useRef, useState} from "react";
import {User} from "@supabase/supabase-js";
import {UserProfile} from "@/app/lib/profile/user-profile";
import {useAtomValue, useSetAtom} from "jotai/index";
import {store} from "@/app/lib/data/appState";
import {useLoadLoggedInUser} from "@/components/common/hooks/useLoadLoggedInUser";
import {fetchProfile} from "@/components/common/hooks/fetch-profile";
import {DEBUG_PROFILE} from "@/components/common/hooks/profile-utils";
import * as Sentry from "@sentry/nextjs";
import {useLocalBrowserAffiliate} from "@/components/common/hooks/useLocalBrowserAffiliate";

const DEBUG = DEBUG_PROFILE;

// same as useLoadProfile but + discounts
export function useLoadProfileWithDiscounts(caller?: string): {
    loadingUser: boolean,
    loadingProfile: boolean,
    user: User | undefined,
    profile: UserProfile | undefined,
    reloadProfile: () => void;
    signOut: (redirectTo?: string | undefined) => void;
    authClient: ReturnType<typeof useLoadLoggedInUser>['authClient'];
} {
/*
    useEffect(() => {
        DEBUG && console.log('Running hook useLoadProfileWithDiscounts');
    }, []);
*/

    const {loadingUser, user, signOut, authClient} = useLoadLoggedInUser();
    const localBrowserAffiliate = useLocalBrowserAffiliate();

    const [initiatedProfileReload, setInitiatedProfileReload] = useState(false);

    const setAppState = useSetAtom(store.state);
    const loadingProfile = useAtomValue(store.loadingProfile);
    const profile = useAtomValue(store.profile);
    const setLoadingProfile = useCallback((value: boolean) => {
        setAppState(state => {
            state.loadingProfile = value;
        })
    }, [setAppState]);
    const setProfile = useCallback((profile: UserProfile | undefined) => {
        setAppState(state => {
            state.profile = profile;
        })
    }, [setAppState]);

    const reloadProfile = useCallback(() => {
        if (user == null) {
            return;
        }
        setLoadingProfile(true);
        setTimeout(() => {
            fetchProfile(user, true, localBrowserAffiliate)
                .then((profile) => {
                    setProfile(profile);
                })
                .catch(e => {
                    Sentry.captureException(e);
                })
                .finally(() => {
                    setLoadingProfile(false);
                })
        }, 0);
    }, [setLoadingProfile, setProfile, user, localBrowserAffiliate]);

    useEffect(() => {
        // If the user is not loaded yet, we can't load the profile
        if (!loadingUser && user == null) {
            setInitiatedProfileReload(true);
            setLoadingProfile(false);
        }
    }, [loadingUser, setLoadingProfile, user]);

    useEffect(() => {
        if (loadingUser || user == null || initiatedProfileReload) {
            return;
        }
        DEBUG && console.log(`[${caller ?? '???'}] Reloading profile...`, user, profile, initiatedProfileReload);
        if (!initiatedProfileReload) {
            setInitiatedProfileReload(true);
        }
        reloadProfile();
    }, [caller, initiatedProfileReload, loadingUser, profile, reloadProfile, user]);

    if (DEBUG) {
        console.log('[useProfileWithDiscounts]', 'loadingUser', loadingUser, 'loadingProfile', loadingProfile, 'user', user, 'profile', profile);
    }

    return {
        loadingUser,
        user,
        loadingProfile: loadingUser || loadingProfile,
        profile,
        reloadProfile,
        signOut,
        authClient
    };
}

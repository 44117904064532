"use client";

import {AppShell, rem, Stack} from "@mantine/core";
import {Header} from "@/components/header/Header";
import {Footer} from "@/components/footer/Footer";
import {useMobile, useTiltedMobile} from "@/components/common/hooks/responsive-utils";
import {DEBUG_BORDERS} from "@/common/utils";
import {useApplyProfileColorScheme} from "@/components/common/hooks/useApplyProfileColorScheme";

export function ShellComponent(
    {
        noHeader,
        noFooter,
        children,

    }: {
        noHeader?: boolean | undefined,
        noFooter?: boolean | undefined,
        children: React.ReactNode
    }) {

    // NB! This is the ONLY place where we should apply the scheme from the profile
    useApplyProfileColorScheme();

    const isMobile = useMobile();
    const isTitledMobile = useTiltedMobile();
    if (isTitledMobile) {
        noFooter = true;
    }

    const headerHeight = 48;
    const footerHeight = 48;
    const mobileVerPadding = 8;
    const desktopVerPadding = 8;

    let removedHeight = headerHeight + footerHeight;
    if (noHeader) {
        removedHeight -= headerHeight;
    }
    if (noFooter) {
        removedHeight -= footerHeight;
    }
    removedHeight += 2 * (isMobile ? mobileVerPadding : desktopVerPadding);
    if (removedHeight < 0) {
        removedHeight = 0;
    }

    return (
        <AppShell
            header={noHeader ? {height: 0} : {height: {base: headerHeight}}}
            footer={noFooter ? {height: 0} : {height: {base: footerHeight}}}
            py={isMobile ? mobileVerPadding : {base: desktopVerPadding}}
            withBorder
        >
            {!noHeader && (
                <AppShell.Header>
                    <Header/>
                </AppShell.Header>
            )}

            <AppShell.Main>
                <Stack id={'main'} gap={0} align={'stretch'}
                       h={`calc(100vh - ${removedHeight}px)`}
                       style={{
                           border: DEBUG_BORDERS ? '1px solid green' : undefined,
                           overflowY: 'auto',
                           overflowX: 'hidden',
                           paddingRight: rem(isMobile ? 0 : 8),
                           paddingLeft: rem(isMobile ? 0 : 8),
                       }}
                >
                    {noHeader && <Header />}
                    {children}
                    {noFooter && <Footer withDivider />}
                </Stack>
            </AppShell.Main>

            {!noFooter && (
                <AppShell.Footer>
                    <Footer />
                </AppShell.Footer>
            )}
        </AppShell>
    )
}
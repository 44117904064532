import {
    ActionIcon,
    Anchor, Divider,
    Group,
    rem,
    Stack,
    Text,
    Tooltip, UnstyledButton,
    useMantineColorScheme,
    useMantineTheme
} from '@mantine/core';
import classes from './Footer.module.css';
import {IconBrandDiscord, IconHeart, IconMail} from "@tabler/icons-react";
import {isSafari, useMobile, useTiltedMobile} from "@/components/common/hooks/responsive-utils";
import {useMemo} from "react";
import {footerLinks} from "@/components/footer/footer-links";

export function Footer({withDivider}: {withDivider?: boolean | undefined}) {

    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();
    const color = scheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[7];
    const isMobile = useMobile();
    const isTilted = useTiltedMobile();

    const items = useMemo(() => {
        return footerLinks.map((link) => {
            if (!link.link.startsWith('mailto') || !isSafari()) {
                return (
                    <Tooltip key={link.link} label={link.hint} withArrow openDelay={350} closeDelay={100}>
                        <Anchor c={color}
                                href={link.link}
                                target={'_blank'}
                                size="xs"
                                ff={'var(--font-montserrat)'}
                                style={{ userSelect: 'none' }}
                        >
                            {link.label}
                        </Anchor>
                    </Tooltip>
                );
            }
            return (
                <Tooltip key={link.link} label={link.hint} withArrow openDelay={350} closeDelay={100}>
                    <form action={link.link} method="get" style={{ padding: 0, margin: 0}}>
                        <UnstyledButton type={'submit'} m={0} pt={4}>
                            <IconMail strokeWidth={1.5} size={16} color={color} />
                        </UnstyledButton>
                    </form>
                </Tooltip>
            )
        });
    }, [color]);

    const socialIcons = useMemo(() => {
        const links = [
            {
                text: 'Join our Discord server to share your experiences and ask questions',
                link: 'https://discord.gg/9qpQGQbjat', icon: <IconBrandDiscord size={18} stroke={1}/>
            },
            /*
                        {
                            text: 'Follow us for news and updates on X/Twitter',
                            link: 'https://twitter.com/TODO', icon: <IconBrandX size={18} stroke={1}/>
                        },
                        {
                            text: 'Watch the community sharing their skills on YouTube',
                            link: 'https://www.youtube.com/channel/TODO', icon: <IconBrandYoutube size={18} stroke={1}/>
                        },
                        {
                            text: 'Watch the community showing off their skills on Instagram',
                            link: 'https://www.instagram.com/TODO/', icon: <IconBrandInstagram size={18} stroke={1}/>
                        },
                        {
                            text: 'Visit the official web site for news, blog articles and more',
                            link: 'https://freelimbs.com', icon: <IconWorldWww size={18} stroke={1}/>
                        }
            */
        ]
        return links.map(({text, link, icon}) => (
            <Tooltip key={link} multiline maw={350} label={text} withArrow openDelay={350} closeDelay={100}>
                <ActionIcon size="sm" color={color} variant="subtle" aria-label={text} component={'a'} href={link} target={'_blank'}>
                    {icon}
                </ActionIcon>
            </Tooltip>
        ));
    }, [color]);

    return (
        <Stack gap={0} px={{base: 18, lg: 40}} justify={'stretch'} h={'100%'}>
            {withDivider && (
                <Divider orientation={'horizontal'} pt={8} />
            )}
            <Group className={classes.inner} justify={'center'} align={'center'}
                   gap={'md'} h={'100%'}>
                <Group align={'center'} gap={isMobile ? 'sm' : 'lg'}>
                    <Text c={color} size="xs" ff={'var(--font-montserrat)'}>
                        &copy; ContraTrouble Ltd. All rights reserved.
                    </Text>
                    {(!isMobile || isTilted) && (
                        <Group gap={'sm'} align={'center'}>{items}</Group>
                    )}
                </Group>
                <Group gap={'md'} align={'center'}>
                    <Group gap={rem(4)} align={'center'} visibleFrom={'lg'} mr={rem(4)}>
                        <Text span c={color} size="xs" fw={500} ff={'var(--font-montserrat)'}>
                            Made with
                        </Text>
                        <IconHeart stroke={1} size={16} color={
                            theme.colors[theme.primaryColor][scheme === 'light' ? 6 : 4]
                        }/>
                    </Group>
                    <Group gap={0} className={classes.social} justify="flex-end" wrap="nowrap">
                        {socialIcons}
                    </Group>
                </Group>
            </Group>
        </Stack>
    )
}
"use client";

import {createTheme, DEFAULT_THEME, MantineColorsTuple, mergeMantineTheme} from "@mantine/core";
import {
    fontSourceCodePro,
    fontNotoSans, fontPlay,
} from "@/components/fonts";

const rougeRed: MantineColorsTuple = [
    "#ffe9ef", // 255, 233, 239
    "#fed4dd",  // 254, 212, 221
    "#f4a8b8",  // 244, 168, 184
    "#eb7891",  // 235, 120, 145
    "#e4516f",  // 228, 81, 111
    "#e0375a", // 224, 55, 90
    "#df284f", // 223, 40, 79
    "#c61b40", // 198, 27, 64
    "#b11138",
    "#9c012e" // 156, 1, 46
];

const goldAura: MantineColorsTuple = [
    "#fff9e2",
    "#fef2ce",
    "#fbe49f",
    "#f8d56b",
    "#f6c841",
    "#f5c025",
    "#f4bc14",
    "#d9a504",
    "#c19300",
    "#a77e00"
];

const mintCream: MantineColorsTuple = [
    "#e8fcf2",
    "#d9f4e7",
    "#b5e5cf",
    "#8ed7b4",
    "#6eca9f",
    "#58c290",
    "#4bbf89",
    "#3ba875",
    "#2f9567",
    "#1d8257"
];

const theme = createTheme({
    // white: '#f9f8fc',
    colors: {
        rougeRed,
        goldAura,
        mintCream,
    },
    primaryColor: 'rougeRed',
    fontFamily: fontNotoSans.style.fontFamily,
    fontFamilyMonospace: fontSourceCodePro.style.fontFamily,
    headings: {
        fontFamily: fontPlay.style.fontFamily
    },
    focusRing: 'never',
});

export const mantineTheme = mergeMantineTheme(DEFAULT_THEME, theme);

"use client";

import {useComputedColorScheme, useMantineColorScheme} from "@mantine/core";
import {useCallback, useEffect, useRef} from "react";
import {DEBUG_SCHEME_SWITCHING} from "@/components/common/hooks/profile-utils";
import {usePreferences} from "@/components/common/hooks/use-preferences";

const DEBUG = DEBUG_SCHEME_SWITCHING;

export function useColorScheme() {
    const systemScheme = useComputedColorScheme('dark', {getInitialValueInEffect: true});
    const systemSchemeRef = useRef(systemScheme);
    useEffect(() => {
        systemSchemeRef.current = systemScheme;
    }, [systemScheme]);

    const {
        setColorScheme: setMantineColorScheme,
    } = useMantineColorScheme();

    const {
        updateColorScheme: updateProfileColorScheme
    } = usePreferences();

    const toggleColorScheme = useCallback(() => {
        const nextScheme = systemSchemeRef.current === 'dark' ? 'light' : 'dark';
        if (DEBUG) {
            console.log('Toggling color scheme, current = ', systemSchemeRef.current, 'next = ', nextScheme);
        }
        setMantineColorScheme(nextScheme);
        setTimeout(() => {
            updateProfileColorScheme( nextScheme, () => {
                if (DEBUG) {
                    console.log(`Updated profile scheme to ${nextScheme}`);
                }
            });
        }, 0);
    }, [setMantineColorScheme, updateProfileColorScheme]);

    return {
        colorScheme: systemSchemeRef.current,
        toggleColorScheme
    };
}

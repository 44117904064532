"use client";

import {Group, rem, Title, Tooltip, UnstyledButton, useMantineColorScheme, useMantineTheme} from "@mantine/core";
import Link from "next/link";
import {CustomLogoIcon} from "@/components/common/icons/CustomLogoIcon";
import Image from "next/image";
import {IS_ALPHA, IS_BETA} from "@/common/utils";

export function Logo() {
    const theme = useMantineTheme();
    const {colorScheme: scheme} = useMantineColorScheme();
/*
    const [hovered, setHovered] = useState(false);
    const onMouseOver = useCallback(() => {
        setHovered(true);
    }, []);
    const onMouseOut = useCallback(() => {
        setHovered(false);
    }, []);
*/

    return (
        <UnstyledButton component={Link} href={'/'} scroll
/*
                        onMouseOver={onMouseOver}
                        onMouseLeave={onMouseOut}
                        style={{opacity: hovered ? 1 : 0.7, transition: 'all 0.05s ease-in-out 0.05s'}}
*/
        >
            <Group gap={rem(2)} align={'center'} wrap={'nowrap'}>
                <CustomLogoIcon
                    size={38}
                    color={theme.colors[theme.primaryColor][5] /*scheme === 'light' ? 'black' : 'white'*/}
                    strokeWidth={1}/>
                <Title order={2} c={scheme === 'light' ? 'black' : 'white'}
                       style={{
                           fontFamily: 'var(--font-logo)',
                           userSelect: 'none',
                       }}
/*
                       style={{
                           textShadow: !hovered ? undefined :
                               scheme === 'light' ?
                                   `1px 1px 1px rgba(54, 54, 54, 0.5), 2px 2px 1px rgba(54, 54, 54, 0.3), 3px 3px 1px rgba(54, 54, 54, 0.1)` :
                                   `1px 1px 1px rgba(204, 204, 204, 0.5), 2px 2px 1px rgba(204, 204, 204, 0.3), 3px 3px 1px rgba(204, 204, 204, 0.1)`,
                       }}
*/
                >FreeLimbs</Title>
                {(IS_ALPHA && !IS_BETA) && (
                    <Group gap={0} ml={rem(4)} align={'center'}>
                        {scheme === 'light' && (
                            <Tooltip label={'Alpha version'} openDelay={350} closeDelay={100}>
                                <Image src={`/images/alpha-light.svg`}
                                       priority={true}
                                       alt={'Alpha version'}
                                       width={16}
                                       height={16}
                                />
                            </Tooltip>
                        )}
                        {scheme === 'dark' && (
                            <Tooltip label={'Alpha version'} openDelay={350} closeDelay={100} position={'right'}>
                                <Image src={`/images/alpha-dark.svg`}
                                       priority={true}
                                       alt={'Alpha version'}
                                       width={16}
                                       height={16}
                                />
                            </Tooltip>
                        )}
                    </Group>
                )}
                {IS_BETA && (
                    <Group gap={0} ml={rem(4)} align={'center'}>
                        {scheme === 'light' && (
                            <Tooltip label={'Beta version'} openDelay={350} closeDelay={100}>
                                <Image src={`/images/beta-light.svg`}
                                       alt={'Beta version'}
                                       width={16}
                                       height={16}
                                />
                            </Tooltip>
                        )}
                        {scheme === 'dark' && (
                            <Tooltip label={'Beta version'} openDelay={350} closeDelay={100} position={'right'}>
                                <Image src={`/images/beta-dark.svg`}
                                       alt={'Beta version'}
                                       width={16}
                                       height={16}
                                />
                            </Tooltip>
                        )}
                    </Group>
                )}
            </Group>
        </UnstyledButton>
    );
}

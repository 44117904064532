"use client";

import {MantineColorScheme} from "@mantine/core";
import {useCallback, useEffect, useState} from "react";
import {useDebouncedCallback} from "@mantine/hooks";
import {useProfile} from "@/components/common/hooks/useProfile";

export function usePreferences() {
    const {loadingProfile, profile} = useProfile();
    const [currentScheme, setCurrentScheme] = useState<MantineColorScheme>('auto' as MantineColorScheme);

    useEffect(() => {
        if (!loadingProfile && profile != null && profile.colorScheme != null && profile.colorScheme !== 'auto') {
            setCurrentScheme((profile.colorScheme ?? 'auto') as MantineColorScheme);
        }
    }, [loadingProfile, profile]);

    const updateColorScheme = useCallback((scheme: MantineColorScheme, doneCallback?: () => void) => {
        setCurrentScheme(scheme);
        if (profile == null) {
            doneCallback?.();
            return;
        }
        fetch('/api/profile/prefs/color-scheme', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({colorScheme: scheme})
        })
            .then(() => {
            })
            .catch(e => console.error('Failed to update color scheme', e))
            .finally(() => {
                doneCallback?.();
            });
    }, [profile]);

    const toggleColorScheme = useCallback((doneCallback?: () => void) => {
        const nextScheme = currentScheme === 'dark' ? 'light' : 'dark';
        updateColorScheme(nextScheme, doneCallback);
    }, [currentScheme, updateColorScheme]);

    return {
        loadingProfile: loadingProfile,
        colorScheme: currentScheme,
        bluetoothAudioLag: loadingProfile ? 0 : (profile?.bluetoothAudioLag ?? 0),
        midiAudioLag: loadingProfile ? 0 : (profile?.midiAudioLag ?? 0),

        toggleColorScheme: useDebouncedCallback(toggleColorScheme, 1000),
        updateColorScheme: useDebouncedCallback(updateColorScheme, 1000),
    }
}

import {useCallback, useEffect, useMemo, useRef} from "react";
import {User} from "@supabase/supabase-js";
import {createClient} from "@/utils/supabase/client";
import {useRouter} from "next/navigation";
import isEqual from "lodash/isEqual";
import {COOKIE_DOMAIN, SIGN_OUT_COOKIES} from "@/common/utils";
import Cookies from "js-cookie";
import {useAtomValue, useSetAtom} from "jotai/index";
import {store} from "@/app/lib/data/appState";
import * as Sentry from "@sentry/nextjs";

export function useLoadLoggedInUser() {
    const localLoadingUser = useRef(false);

    const setAppState = useSetAtom(store.state);
    const loadingUser = useAtomValue(store.loadingUser);
    const user = useAtomValue(store.user);
    const setLoadingUser = useCallback((value: boolean) => {
        localLoadingUser.current = value;
        setAppState(state => {
            state.loadingUser = value;
        })
    }, [setAppState]);
    const setUser = useCallback((user: User | undefined | null) => {
        setAppState(state => {
            state.user = user == null ? undefined : user;
        })
    }, [setAppState]);

    const supabase = useMemo(() => createClient(), []);
    const router = useRouter();

    useEffect(() => {
        const {data} = supabase.auth.onAuthStateChange((event, session) => {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            setTimeout(() => {
                if (event === "INITIAL_SESSION") {
                    if (session?.user != null) {
                        if (!isEqual(session?.user, user)) {
                            setUser(session?.user ?? null);
                        }
                        setLoadingUser(false);
                    } else {
                        setTimeout(() => {
                            supabase.auth.getUser().then(response => {
                                if (!isEqual(response.data.user, user)) {
                                    setUser(response.data.user ?? null);
                                }
                                setLoadingUser(false);
                            }).catch(e => {
                                Sentry.captureException(e);
                                console.error('Failed to get user', e);
                                setLoadingUser(false);
                            })
                        }, 0);
                    }
                } else if (event === "SIGNED_IN") {
                    if (!isEqual(session?.user, user)) {
                        setUser(session?.user ?? null);
                    }
                    setLoadingUser(false);
                } else if (event === "SIGNED_OUT") {
                    setUser(null);
                    setLoadingUser(false);
                }
            }, 0);
        });
        return () => {
            data.subscription.unsubscribe();
        };
    }, [setLoadingUser, setUser, supabase, user]);

    const signOut = useCallback((redirectTo?: string | undefined) => {
        supabase.auth.signOut()
            .then(() => {
            })
            .catch(e => {
                Sentry.captureException(e);
                console.error('Failed to sign out', e);
            })
            .finally(() => {
                // clean up after Supabase
                SIGN_OUT_COOKIES.forEach(cookie => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    try {
                        Cookies.remove(cookie, {domain: COOKIE_DOMAIN})
                    } catch (e) {
                    }
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    try {
                        Cookies.remove(cookie)
                    } catch (e) {
                    }
                });
                setUser(null);
                if (redirectTo != null) {
                    router.push(redirectTo);
                }
            })
    }, [router, setUser, supabase.auth]);

    return {loadingUser, user, authClient: supabase, signOut};
}
"use client";

import {
    Group,
    Menu,
    Burger,
    Button,
    Divider, Anchor, ActionIcon, Stack, ScrollArea, UnstyledButton
} from '@mantine/core';
import {useDisclosure} from '@mantine/hooks';
import classes from './Header.module.css';
import {Logo} from "@/components/common/Logo";
import {
    BwMd, isSafari,
    useBreakpointWidth,
    useMobile, useShortScreen, useTiltedMobile
} from "@/components/common/hooks/responsive-utils";
import {useToolbarButtons} from "@/components/common/hooks/useToolbarButtons";
import Link from "next/link";
import {useCallback, useMemo, useState} from "react";
import {useSlightlyDimmedColor} from "@/components/common/hooks/useColors";
import {APP_URL, IS_PAID_IN_PRODUCTION} from "@/common/utils";
import {footerLinks} from "@/components/footer/footer-links";
import {Span} from "@/components/common/Span";
import {IconLogout, IconUserCircle} from "@tabler/icons-react";
import {useLoadProfileWithDiscounts} from "@/components/common/hooks/useLoadProfileWithDiscounts";
import {useSafePathName} from "@/components/common/hooks/useSafePathName";

const navigationLinks = [
    {key: 'home', href: '/', label: 'Home'},
];

navigationLinks.push({key: 'how-it-works', href: '/how-it-works', label: 'How it works'});

if (IS_PAID_IN_PRODUCTION) {
    navigationLinks.push({key: 'faq', href: '/faq', label: 'FAQ'});
    navigationLinks.push({key: 'pricing', href: `/pricing`, label: 'Pricing'});
    navigationLinks.push({key: 'affiliate', href: '/affiliates', label: 'Affiliates'});
}

navigationLinks.push({key: 'about', href: '/about', label: 'About'});

export function Header() {
    const [burgerMenuOpened, {toggle: toggleBurgerMenu, close: closeBurgerMenu}] = useDisclosure(false);
    const [userMenuOpened, setUserMenuOpened] = useState(false);

    const isMobile = useMobile();
    const bpWidth = useBreakpointWidth();
    const isShort = useShortScreen();
    const isTilted = useTiltedMobile();
    const pathName = useSafePathName();
    const inAuth = pathName.includes('/auth/');

    const {user, signOut} = useLoadProfileWithDiscounts('Header');
    const onSignOut = useCallback(() => {
        closeBurgerMenu();
        if (user == null) {
            return;
        }
        signOut('/');
    }, [closeBurgerMenu, signOut, user]);

    const {
        desktopButtons,
        mobileMenuItems,
    } = useToolbarButtons({
        onComplete: closeBurgerMenu
    });

    const dimmed = useSlightlyDimmedColor();

    const navLinks = useMemo(() => {
        let navLinks = navigationLinks;
        if (isTilted) {
            navLinks = navLinks.filter(({key}) => ['home', 'pricing'].includes(key));
        }
        return navLinks.map(({key, href, label}) => (
            pathName === href ?
                <Span key={key} c={'dimmed'} fz={isMobile ? 'sm' : 'md'}
                      style={{pointerEvents: 'none'}} ff={'var(--font-play)'}>{label}</Span> :
                <Anchor size={isMobile ? 'sm' : 'md'} key={key} scroll={false}
                        component={Link}
                        href={href}
                        ff={'var(--font-play)'}
                        c={dimmed}>{label}</Anchor>
        ));
    }, [dimmed, isMobile, isTilted, pathName]);

    return (
        <Stack className={classes.header} gap={0} justify={'center'} h={48}>
            <Group className={classes.mainSection} justify="space-between" align={'center'}
                   px={{base: 18, lg: 40}} wrap={'nowrap'}>
                <Group gap={isMobile ? 'xs' : isShort ? 'sm' : 'md'} align={'center'}
                       wrap={'nowrap'}>
                    <Logo/>
                    <Group visibleFrom={'md'} gap={'md'} align={'center'}>
                        <Divider orientation={'vertical'}/>
                        <Group gap={'md'} align={'center'}>
                            {navLinks}
                        </Group>
                    </Group>
                </Group>

                <Group justify="space-between" align={'center'} gap={'xs'} wrap={'nowrap'}>

                    {(isMobile && user != null && !inAuth) && (
                        <Button mt={4}
                                className={'border-animated'}
                                variant={'filled'}
                                component={'a'}
                                href={APP_URL}
                                size={'xs'}>
                            Launch app
                        </Button>
                    )}

                    <Group mt={isMobile ? 4 : 0} visibleFrom="xs" gap={'xs'}>

                        {(!isMobile && user != null && !inAuth) && (
                            <Button variant={'filled'}
                                    className={'border-animated'}
                                    component={'a'}
                                    href={APP_URL}
                                    size={'sm'}>
                                Launch app
                            </Button>
                        )}

                        {(user == null && !inAuth) && (
                            <>
                                <Button variant="default"
                                        component={'a'}
                                        href={`/auth/sign-in?redirectedFrom=${pathName}`}
                                        size={(isMobile || isShort) ? 'xs' : 'sm'}
                                >Sign in</Button>
                                <Button variant='filled'
                                        component={'a'}
                                        size={(isMobile || isShort) ? 'xs' : 'sm'}
                                        href={`/auth/sign-up`}
                                >
                                    Get started</Button>
                            </>
                        )}

                        {desktopButtons}
                    </Group>

                    {(!isMobile && user != null && !inAuth) && (
                        <Menu
                            withArrow
                            shadow={'xl'}
                            trigger={'click'}
                            closeOnItemClick
                            closeOnClickOutside
                            closeOnEscape
                            position="bottom-end"
                            transitionProps={{transition: 'pop-top-right'}}
                            opened={userMenuOpened}
                            onClose={() => setUserMenuOpened(false)}
                            onOpen={() => setUserMenuOpened(true)}
                            withinPortal={false}
                        >
                            <Menu.Target>
                                <ActionIcon size={32} variant={'subtle'} radius={'xl'}>
                                    <IconUserCircle size={32} strokeWidth={1.5}/>
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown bg={'var(--mantine-color-body)'}>
                                <Menu.Label>{user.email}</Menu.Label>
                                <Menu.Divider/>
                                <Menu.Item onClick={onSignOut}
                                           leftSection={
                                               <IconLogout size={16} stroke={1.5}/>
                                           }
                                >
                                    Logout
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    )}

                    {(isMobile || bpWidth <= BwMd) && (
                        <Group gap={0} mt={isMobile ? 4 : 0}>
                            <Menu withArrow
                                  withinPortal={false}
                                  shadow={'xl'}
                                  trigger={'click'}
                                  closeOnItemClick
                                  closeOnClickOutside
                                  closeOnEscape>
                                <Menu.Target>
                                    <Burger opened={burgerMenuOpened} onClick={toggleBurgerMenu} size="sm"
                                            aria-label="Toggle navigation"/>
                                </Menu.Target>
                                <Menu.Dropdown bg={'var(--mantine-color-body)'}>
                                    <ScrollArea type={'scroll'} scrollHideDelay={500} offsetScrollbars
                                                h={isTilted ? 300 : '100%'}>
                                        {navigationLinks
                                            .filter(({href}) => href !== pathName)
                                            .map(({key, href, label}) => (
                                                <Menu.Item key={key} component={Link} href={href}
                                                           onClick={closeBurgerMenu}>{label}
                                                </Menu.Item>
                                            ))}

                                        {(user == null && !inAuth && !isTilted) && (
                                            <>
                                                <Menu.Divider/>
                                                <Menu.Item component={'a'}
                                                           href={`/auth/sign-in?redirectedFrom=${pathName}`}
                                                           onClick={closeBurgerMenu}>
                                                    Sign in
                                                </Menu.Item>
                                                <Menu.Item component={'a'}
                                                           href={`/auth/sign-up`}
                                                           onClick={closeBurgerMenu}>
                                                    Get started
                                                </Menu.Item>
                                            </>
                                        )}

                                        <Menu.Divider/>
                                        {mobileMenuItems}

                                        <Menu.Divider/>
                                        {footerLinks.map(({link, label}) => {
                                            if (!link.startsWith('mailto') || !isSafari()) {
                                                return (
                                                    <Menu.Item key={label} component={'a'} href={link}
                                                               onClick={closeBurgerMenu}>{label}</Menu.Item>
                                                );
                                            }
                                            return (
                                                <Menu.Item key={label} onClick={closeBurgerMenu}>
                                                    <form action={link} method="get"
                                                          style={{padding: 0, margin: 0}}>
                                                        <UnstyledButton type={'submit'} m={0} p={0}>
                                                            <Span fz={'sm'}>Contact</Span>
                                                        </UnstyledButton>
                                                    </form>
                                                </Menu.Item>
                                            );
                                        })}

                                        {user != null && !inAuth && (
                                            <>
                                                <Menu.Divider/>
                                                <Menu.Item
                                                    leftSection={<IconLogout size={16} stroke={1.5}/>}
                                                    onClick={onSignOut}
                                                >
                                                    Logout
                                                </Menu.Item>
                                            </>
                                        )}

                                    </ScrollArea>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    )}
                </Group>
            </Group>
        </Stack>
    );
}

"use client";

import {useComputedColorScheme, useMantineColorScheme} from "@mantine/core";
import {useEffect, useRef, useState} from "react";
import {DEBUG_SCHEME_SWITCHING} from "@/components/common/hooks/profile-utils";
import {usePreferences} from "@/components/common/hooks/use-preferences";

const DEBUG = DEBUG_SCHEME_SWITCHING;

export function useApplyProfileColorScheme() {
    const systemScheme = useComputedColorScheme('dark', {getInitialValueInEffect: true});
    const systemSchemeRef = useRef(systemScheme);
    useEffect(() => {
        systemSchemeRef.current = systemScheme;
    }, [systemScheme]);

    const {
        setColorScheme: setMantineColorScheme,
    } = useMantineColorScheme();

    const {
        loadingProfile,
        colorScheme: profileColorScheme,
    } = usePreferences();

    const [updatedMantineScheme, setUpdatedMantineScheme] = useState(false);
    useEffect(() => {
        if (updatedMantineScheme || loadingProfile || profileColorScheme === 'auto') {
            return;
        }
        setUpdatedMantineScheme(true);
        if (DEBUG) {
            console.log(`Profile loaded. Changing scheme from ${systemSchemeRef.current} to ${profileColorScheme}`);
        }
        setMantineColorScheme(profileColorScheme);
    }, [loadingProfile, profileColorScheme, setMantineColorScheme, updatedMantineScheme]);
}

import {
    ActionIcon, MantineSpacing, Menu, Tooltip, useComputedColorScheme
} from "@mantine/core";
import {useCallback, useEffect, useMemo} from "react";
import {HotkeyItem, useHotkeys} from "@mantine/hooks";
import {IconMoon, IconSunHigh} from "@tabler/icons-react";
import {usePrimaryColor} from "@/components/common/hooks/useColors";
import {useColorScheme} from "@/components/common/hooks/useColorScheme";

export function useToolbarButtons(
    {
        onComplete,
        spacing,
    }: {
        onComplete?: () => void,
        spacing?: MantineSpacing | undefined
    }
) {
    const {colorScheme: scheme, toggleColorScheme} = useColorScheme();
    const computedColorScheme = useComputedColorScheme(scheme, {getInitialValueInEffect: true});
    const primaryColor = usePrimaryColor();

    useEffect(() => {
        document.body.style.backgroundImage = `url('/images/backdrop-${scheme}.png')`
    }, [scheme]);

    useEffect(() => {
        document.body.style.backgroundImage = `url('/images/backdrop-${computedColorScheme}.png')`
    }, [computedColorScheme]);

    const onToggleColorScheme = useCallback(() => {
        toggleColorScheme();
        onComplete?.();
    }, [toggleColorScheme, onComplete]);

    const hotKeys: HotkeyItem[] = useMemo(() => {
        const keys: HotkeyItem[] = [];
        keys.push(['mod+/', toggleColorScheme]);
        return keys;
    }, [toggleColorScheme]);

    useHotkeys(hotKeys);

    const mobileMenuItems = useMemo(() => {
        return (
            <>
                <Menu.Item onClick={onToggleColorScheme} leftSection={
                    scheme === 'dark' ?
                        <IconSunHigh color={primaryColor} size={16} stroke={1.5}/> :
                        <IconMoon color={primaryColor} size={16} stroke={1.5}/>
                }>{scheme === 'dark' ? 'Light' : 'Dark'} theme</Menu.Item>
            </>

        );
    }, [onToggleColorScheme, primaryColor, scheme]);

    const desktopButtons = useMemo(() => {
        return (
            <Tooltip label={`Switch to ${scheme === 'dark' ? 'light' : 'dark'} theme`} position={'bottom'}
                     openDelay={350} closeDelay={100}>
                <ActionIcon variant={'subtle'} onClick={onToggleColorScheme}>
                    {scheme === 'dark' ? <IconSunHigh color={primaryColor} size={32} stroke={1.5}/> :
                        <IconMoon color={primaryColor} size={32} stroke={1.5}/>}
                </ActionIcon>
            </Tooltip>
        );
    }, [onToggleColorScheme, primaryColor, scheme]);

    return {desktopButtons, mobileMenuItems};
}

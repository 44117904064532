import {rem} from '@mantine/core';
import {ComponentPropsWithoutRef} from "react";

interface CustomLogoIconProps extends ComponentPropsWithoutRef<'svg'> {
    size?: number | string;
}

export function CustomLogoIcon({size, style, ...others}: CustomLogoIconProps) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 100 100"
             fill={others.color ?? "currentColor"}
             stroke={others.color ?? "currentColor"}
             strokeLinecap="round"
             strokeLinejoin="round"
             strokeWidth="1.5"
             imageRendering="optimizeQuality"
             shapeRendering="geometricPrecision"
             textRendering="geometricPrecision"
             style={{width: rem(size), height: rem(size), ...style}}
             {...others}
        >
            <path d="M85.929,35.423l-5.376-9.34c-0.001-0.002-0.001-0.004-0.002-0.006c-0.202-0.348-0.574-0.563-0.977-0.563l-20.858-0.003
	l-5.048-8.771c-0.201-0.35-0.575-0.565-0.978-0.565l-32.264,0.001c-0.403,0-0.776,0.215-0.977,0.565l-5.378,9.339
	c-0.202,0.349-0.202,0.779,0,1.128c0.001,0.002,0.003,0.003,0.004,0.005l10.428,18.115l-5.052,8.779c0,0,0,0-0.001,0
	c0,0.001,0,0.001,0,0.001l-0.001,0.001c-0.025,0.044-0.038,0.093-0.057,0.14c-0.018,0.044-0.042,0.087-0.054,0.133
	c-0.015,0.056-0.016,0.113-0.023,0.17c-0.004,0.04-0.015,0.079-0.015,0.119c0,0.04,0.011,0.079,0.015,0.119
	c0.006,0.057,0.008,0.115,0.023,0.171c0.013,0.05,0.039,0.096,0.059,0.144c0.018,0.043,0.03,0.088,0.053,0.129l0.001,0.002v0
	c0.001,0.001,0.001,0.001,0.001,0.001L35.58,83.26c0.2,0.349,0.574,0.565,0.977,0.565l10.753-0.003c0.403,0,0.775-0.215,0.976-0.563
	c0-0.001,0-0.001,0.001-0.002c0-0.001,0-0.001,0-0.001l10.425-18.118l10.108,0.001c0.101,0,0.2-0.014,0.294-0.039
	c0.072-0.02,0.139-0.053,0.206-0.086c0.02-0.01,0.043-0.015,0.063-0.026c0.153-0.089,0.282-0.217,0.379-0.37
	c0.01-0.015,0.024-0.027,0.033-0.042c0-0.001,0-0.001,0-0.001c0,0,0.001,0,0.001-0.001l16.132-28.027
	C86.131,36.2,86.131,35.772,85.929,35.423z M56.111,65.139L47.31,80.434L35.366,59.686l-2.888-5.017l9.453-16.426l4.077,7.084
	l-5.054,8.776c-0.101,0.174-0.151,0.368-0.151,0.563c0,0.195,0.05,0.389,0.151,0.564c0.001,0.002,0.003,0.003,0.004,0.005
	l5.375,9.338c0.2,0.349,0.574,0.565,0.977,0.565l8.803-0.003L56.111,65.139z M37.209,81.57L22.376,55.798l0,0l8.15-0.002l0,0
	L45.36,81.568L37.209,81.57z M25.801,43.064L16.997,27.77l29.662-0.001l9.453,16.427l0,0l-8.151,0.003l-5.054-8.78l0,0
	c-0.023-0.04-0.056-0.071-0.083-0.108c-0.012-0.015-0.025-0.028-0.037-0.043c-0.026-0.03-0.049-0.063-0.079-0.09
	c-0.135-0.135-0.299-0.235-0.484-0.285c-0.095-0.025-0.193-0.039-0.294-0.039l-10.752,0.003c-0.101,0-0.2,0.014-0.294,0.039
	c-0.07,0.019-0.135,0.051-0.2,0.083c-0.022,0.012-0.048,0.016-0.07,0.029c-0.169,0.098-0.312,0.239-0.413,0.414l-0.797,1.384
	L25.801,43.064z M52.038,18.43L62.14,35.984l-4.077,7.079l-4.097-7.118l-5.678-9.865c-0.151-0.262-0.399-0.449-0.683-0.526
	c-0.095-0.025-0.194-0.039-0.294-0.039l-30.314,0l4.08-7.084L52.038,18.43z M60.014,27.766l17.609,0.003L62.79,53.54l0,0
	l-4.737-0.001l-14.171-0.002h-0.001l2.504-4.347l1.577-2.737l10.101-0.004c0.403,0,0.776-0.215,0.977-0.564l0.002-0.004l5.376-9.335
	c0.126-0.217,0.164-0.466,0.132-0.707c-0.019-0.145-0.057-0.288-0.132-0.419l-4.406-7.655H60.014z M31.831,37.112l8.15-0.002h0.001
	l-9.455,16.431h0l-8.149,0.002L31.831,37.112z M58.097,62.884c-0.012,0-0.022-0.004-0.034-0.004l-10.101,0.003l-4.08-7.09
	l14.171,0.002l4.737,0.001l4.08,7.09L58.097,62.884z M68.821,61.753l-4.077-7.085l14.83-25.767l4.077,7.085L68.821,61.753z"
            ></path>
        </svg>
    )
}
